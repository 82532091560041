const logotext = "Mo Seals";
const meta = {
    title: "Maurice Seals",
    description: "I’m Maurice Seals, a Data Center Technician",
};

const introdata = {
    title: "Hello I’m Maurice Seals",
    animated: {
        first: "I'm a data center technician",
        second: "I love coding",
        third: "I'm a veteran",
        fourth:"My favorite color is purple"
    },
    description: `Former US Navy Yeoman turned tech enthusiast, I've smoothly sailed into the IT world and I'm loving every byte of it! I bring a mix of military discipline, tech wizardry, and a dash of customer-first attitude to my roles. With a knack for fixing computers and a passion for problem-solving, I'm your go-to person for all things IT, ready to keep your systems shipshape and your team smiling!`,
    your_img_url: "https://i.ibb.co/RcNJgJb/IMG-8462.jpg",
};
const dataabout = {
    title: "abit about my self",
    aboutme: `A former US Navy Yeoman, I've successfully transitioned into the 
    dynamic world of IT and am thoroughly enjoying every moment of it. With a strong 
    foundation in military service and a newfound passion for technology, I bring a unique 
    blend of discipline, adaptability, and technical expertise to my roles. Throughout my 
    career, I have acquired valuable skills in computer repair, technical support, and team
     management, which have been further enhanced by my experiences as a Data Center Facilities 
     Technician at Salute Mission Critical and as a Store Manager at Turners Computers. In my 
     previous roles, I've honed my abilities in troubleshooting hardware, coordinating with IT 
     departments for business continuity, and effectively managing resources. My background in 
     customer-focused roles, including my time as a Warehouse Team Member at Amazon, has 
     instilled in me a commitment to exceeding customer expectations and ensuring a seamless 
     service experience. My journey in the US Navy as a Yeoman equipped me with organizational 
     skills, attention to detail, and the ability to handle diverse responsibilities. I have 
     experience in office management, database maintenance, and coordination of activities 
     that have contributed to my well-rounded skill set. As I continue to evolve in the IT 
     field, I am dedicated to expanding my knowledge and expertise. I hold certifications in 
     JavaScript Algorithms and Data Structures from freeCodeCamp and possess skills in system 
     monitoring, network troubleshooting, and various software and protocols. i am eager to 
     leverage my military background, technical prowess, and commitment to excellence in my 
     pursuit of contributing to the success of your organization. "
    `
}
const worktimeline = [
  {
  jobtitle: "Freelance IT Specialist",
  where: "Self-Employed",
  date: "2017 - Present",
  },
  {
        jobtitle: "Datacenter Facilities Technician",
        where: "Salute Mission Critical",
        date: "2022",
    },
    {
        jobtitle: "Yeoman",
        where: "US Navy",
        date: "2016",
    },
];

const skills = [
    {
        name: "Javascript",
        value: 90,
    },
    {
        name: "React",
        value: 80,
    },
    {
        name: "Python",
        value: 70,
    },

];

const services = [{
        title: "UI & UX Design",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Mobile Apps",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Wordpress Design",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
];

const dataportfolio = [
    /*
    {
        img: "https://picsum.photos/400/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/800/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/600/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
*/
];

const contactConfig = {
    YOUR_EMAIL: "contact@mauriceseals.com",
    YOUR_FONE: "(503)548-3175",
    description: " ",
    YOUR_SERVICE_ID: "service_v0y1fe4",
    YOUR_TEMPLATE_ID: "template_mrk3nkf",
    YOUR_PUBLIC_KEY: "tzq_jVp9kbAn0hRU_",
};

const socialprofils = {
    github: "https://github.com",
    facebook: "https://facebook.com",
    linkedin: "https://www.linkedin.com/in/maurice-seals/",
    twitter: "https://twitter.com",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};
